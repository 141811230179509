@import './media.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

@font-face {
  font-family: 'Averta';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/TMSans.woff') format('woff'), url('./assets/fonts/TMSans-Regular.woff2') format('woff2');
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Averta';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}
